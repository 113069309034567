<template>
  <div class="lists-table">
    <v-row no-gutters class="row-header">
      <v-col cols="3" sm="3">
        <strong>{{ $t("lists.header.name") }}</strong>
      </v-col>
      <v-col cols="5" sm="5">
        <strong>{{ $t("lists.header.productsQty") }}</strong>
      </v-col>
      <v-col cols="4" sm="4"> </v-col>
    </v-row>

    <v-list>
      <v-list-item
        v-for="list in lists"
        :key="list.id"
        v-bind:to="'/profile/lists/' + list.id"
      >
        <v-row no-gutters align="center" v-if="!list.isEdit">
          <v-col cols="3" sm="3">
            <div
              class="text-caption text-sm-body-2 primary--text font-weight-bold"
            >
              {{ list.name }}
            </div>
          </v-col>
          <v-col cols="2" sm="5">
            <span
              class="text-caption text-sm-body-2 d-flex justify-center justify-sm-start text-center"
              >{{
                $vuetify.breakpoint.xs
                  ? list.size
                  : $tc("lists.products", list.size)
              }}</span
            >
          </v-col>
          <v-col
            cols="7"
            sm="4"
            class="d-flex justify-end align-center pt-3 pt-sm-0"
          >
            <v-tooltip bottom v-if="!list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-flex flex-column justify-center align-center mr-1 mr-sm-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    v-on:click.prevent="setDefault(list.id)"
                    small
                    class="primary white--text"
                    icon
                    fab
                  >
                    <v-icon>$ratingEmpty</v-icon>
                  </v-btn>
                  <span class="primary--text text-center small">&nbsp;</span>
                </div>
              </template>
              <span>{{ $t("lists.button.setDefault") }}</span>
            </v-tooltip>

            <v-tooltip bottom v-else-if="list.isDefault">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-flex flex-column justify-center align-center mr-1 mr-sm-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                    v-on:click.prevent="setDefault(list.id)"
                    small
                    class="primary white--text"
                    icon
                    fab
                  >
                    <v-icon>$ratingFull</v-icon>
                  </v-btn>
                  <span class="primary--text text-center small">&nbsp;</span>
                </div>
              </template>
              <span>{{ $t("lists.button.default") }}</span>
            </v-tooltip>

            <div
              class="d-flex flex-column justify-center align-center mr-1 mr-sm-4"
            >
              <v-btn
                @click.stop.prevent="addAllToCart(list)"
                small
                class="primary white--text"
                icon
                fab
              >
                <v-icon>$cart</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("lists.button.reorder")
              }}</span>
            </div>

            <div
              class="d-flex flex-column justify-center align-center mr-1 mr-sm-2"
            >
              <v-btn
                @click.stop.prevent="setEdit(list)"
                small
                class="primary white--text"
                icon
                fab
              >
                <v-icon>$edit</v-icon>
              </v-btn>
              <span class="primary--text text-center small">{{
                $t("lists.button.edit")
              }}</span>
            </div>

            <div class="d-flex flex-column justify-center align-center ">
              <v-btn
                @click.stop.prevent="removeList(list.id, list.name)"
                small
                class="promo--text"
                icon
                fab
              >
                <v-icon>$delete</v-icon>
              </v-btn>
              <span class="promo--text text-center small">{{
                $t("lists.button.delete")
              }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters align="center" v-if="list.isEdit">
          <!-- label="Inserisci il nome" -->
          <v-col cols="4">
            <v-text-field
              class="rounded-lg"
              clearable
              filled
              solo
              hide-details="auto"
              v-model="list.tempName"
              v-on:click.prevent="() => {}"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="8" md="2">
            <v-row no-gutters justify="center">
              <div class="d-flex flex-column justify-center align-center mr-8">
                <v-btn
                  small
                  class="primary white--text"
                  icon
                  fab
                  v-on:click.prevent="confirmEdit(list)"
                >
                  <v-icon>$complete</v-icon>
                </v-btn>
                <span class="primary--text text-center small">{{
                  $t("lists.button.confirm")
                }}</span>
              </div>
              <div class="d-flex flex-column justify-center align-center">
                <v-btn
                  small
                  class="promo--text"
                  icon
                  fab
                  v-on:click.prevent="undoEdit(list)"
                >
                  <v-icon>$close</v-icon>
                </v-btn>
                <span class="promo--text text-center small">{{
                  $t("lists.button.discard")
                }}</span>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style scoped lang="scss">
.lists-table {
  .small {
    font-size: 10px;
    min-height: 16px;
    text-transform: uppercase;
  }
}
</style>
<script>
import Vue from "vue";
import { mapActions } from "vuex";

export default {
  name: "ListsTable",
  props: {
    lists: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    setDefault(listId) {
      this.$emit("setdefault", listId);
    },
    removeList(listId, listName) {
      this.$emit("remove", { listId, listName });
    },
    setEdit(list) {
      Vue.set(list, "isEdit", true);
      Vue.set(list, "tempName", list.name);
    },
    undoEdit(list) {
      list.isEdit = false;
      list.tempName = null;
    },
    confirmEdit(list) {
      this.$emit("rename", list);
    },
    async addAllToCart(list) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(list.id);
      }
    }
  }
};
</script>

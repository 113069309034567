<template>
  <div class="generic-list scroll-container" :key="componentKey">
    <CategoryTitle :category="category" :titleIcon="'$heart'" />

    <v-row class="d-flex flex-column mt-8 px-0" no-gutters>
      <v-col sm="12" md="4">
        <div class="text-body-2 font-weight-bold">
          {{ $t("lists.header.create") }}
        </div>

        <div class="d-flex align-center">
          <v-text-field
            :placeholder="$t('lists.insert')"
            hide-details="auto"
            v-model="listName"
          ></v-text-field>
          <v-btn
            elevation="2"
            medium
            class="primary"
            v-on:click="createList()"
            :disabled="createDisabled"
            >{{ $t("lists.button.create") }}</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <ListsTable
      class="mt-6"
      :lists="lists"
      @setdefault="setDefault"
      @remove="removeList"
      @rename="renameList"
    ></ListsTable>
    <v-card
      light
      outlined
      v-if="lists && lists.length == 0"
      class="pa-2 text-center"
    >
      <v-card-title class="font-weight-bold">{{
        $t("lists.noListFound")
      }}</v-card-title>
    </v-card>
  </div>
</template>
<script>
// @ is an alias to /src
import CategoryTitle from "@/components/category/CategoryTitle";
import ListsTable from "@/components/lists/ListsTable";

import list from "~/mixins/list";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "Lists",
  mixins: [list, categoryMixins, deliveryReactive],
  data() {
    return {
      page: {},
      componentKey: 0
    };
  },
  components: {
    CategoryTitle,
    ListsTable
  },
  methods: {
    //refreshes list items default button status
    async setDefault(listId) {
      await this.setDefaultList(listId);
      this.componentKey++;
    }
  },
  created() {
    this.reload();
  }
};
</script>
